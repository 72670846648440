body {
	background-color: #34495e;
	font-family: "Open Sans", sans-serif;
	color: #ffffff;
	margin: 0;
	font-size: 18px;
}

h1 {
	font-family: "Permanent Marker", cursive;
	letter-spacing: 0.1em;
	margin: 0 0 50px 0;
	font-size: 2.5em;
	text-align: center;
}

h2 {
	text-align: center;
}

img {
	margin-bottom: 30px;
}

.container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

p {
	text-align: center;
	width: 45%;
}

a {
	color: #ffffff;
	text-decoration: none;
}

a:hover {
	color: #c0392b;
}

@media (min-width: 320px) {
	.container {
		padding: 20px;
	}

	.container div {
		width: 80%;
		margin: 0 auto;
	}

	p {
		width: 100%;
		font-size: 16px;
	}

	img {
		width: 100%;
		text-align: center;
	}
}

@media (min-width: 550px) {
	.container div {
		width: 60%;
		margin: 0 auto;
	}
}

@media (min-width: 550px) {
	.container div {
		width: 50%;
		margin: 0 auto;
	}
}

@media (min-width: 768px) {
	.container {
		height: 100vh;
		max-height: 100vh;
		padding: 0px;
	}

	.container div {
		width: 20%;
		margin: 0 auto;
	}

	p {
		width: 45%;
	}
}
